import { Typography } from '@mui/material'

// Types
import type { TypographiesProps } from '@/types/components/atoms/typographies/main-header'

const MainHeaderAtom = (props: TypographiesProps) => {
	// Props
	const { text, sx } = props
	return (
		<Typography fontSize={{ xs: 32, md: 48 }} fontWeight={700} sx={{ ...sx }}>
			{text}
		</Typography>
	)
}

export default MainHeaderAtom
